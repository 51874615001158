import './App.css';
import React, {useEffect, useRef, useState} from 'react';


function ProjectCard({project}) {
  if (!project) {
    return;
  }

  return (
      <section
          className={'project'}
          style={{...project?.customStyle}}
      >
        <div style={{padding: '40px'}}>
          <h3>{project.tag}</h3>
          <h2>{project.title}</h2>
          <p className="role">{project.role}</p>
          <p className="description">{project.description}</p>
          <p className="technologies">{project.technologies}</p>
          {project.link && (
              <a
                  className="button_black"
                  href={project.link}
                  target="_blank"
                  rel="noopener"
              >
                CHECK {project.id % 2 === 0 ? 'DEMO' : 'COMPANY'}
              </a>
          )}
          {project.note && (
              <small
                  className="note"
                  dangerouslySetInnerHTML={{
                    __html: project.note.replace(/\n/g, '<br/>'),
                  }}
              />
          )}

        </div>
      </section>
  );
}

function App() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentProject, setCurrentProject] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;

      // Calculate the width and height of each grid square
      const gridWidth = window.innerWidth / 3;
      const gridHeight = window.innerHeight / 3;

      // Determine the index of the grid square based on mouse coordinates
      const xIndex = Math.floor(clientX / gridWidth);
      const yIndex = Math.floor(clientY / gridHeight);
      const index = yIndex * 3 + xIndex;

      setHoveredIndex(index);
    };

    // Add mousemove event listener
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const images = [
    '/images/tiny/1_face.png','/images/tiny/4_face.png','/images/tiny/7_face.png',
    '/images/tiny/2_face.png','/images/tiny/5_face.png', '/images/tiny/8_face.png',
    '/images/tiny/3_face.png', '/images/tiny/6_face.png','/images/tiny/9_face.png'
  ];

  const projects = [
    {
      id: 1,
      tag: "CURRENT COMPANY",
      title: "Qwertify",
      role: "Full Stack Developer",
      description: "I'm a full stack developer working with backend (PHP/Laravel), frontend (Next.js/React), and mobile (React Native) technologies, where I create APIs, web applications, and mobile applications.",
      technologies: "PHP / SQL / Laravel / HTML / CSS / JavaScript / React / React Native",
      link: "https://qwertify.io",
      customStyle:{clipPath: 'polygon(100% 0, 100% 100%, 82% 100%, 4% 92%, 0 18%)'},
    },
    {
      id: 2,
      tag: "PROJECT",
      title: "Video Plus",
      role: "Full Stack Developer",
      description: "Video Plus is an online platform for posting video, photo, and text articles. It has features such as login system, email notifications, video compression, thumbnail creation, filters and searches etc.",
      technologies: "PHP / Laravel / HTML / CSS / Bootstrap / JavaScript / jQuery / SQL",
      link: "http://videoplus.rs",
      note: "Register to see some of the functionalities.",
      customStyle:{clipPath: 'polygon(100% 15%, 100% 87%, 64% 97%, 4% 92%, 0 0)'},
    },
    {
      id: 3,
      tag: "COMPANY",
      title: "AVALARC: Hubspot CMS Templates",
      role: "Front End Developer / Designer",
      description: "Developed custom HubSpot CMS Themes for the HubSpot official marketplace. Created over 30 different templates ranging from full websites, landing pages to email templates.",
      technologies: "HubL(Jinjava) / HTML / CSS / Bootstrap / JavaScript / jQuery",
      customStyle:{clipPath: 'polygon(100% 0, 100% 100%, 82% 100%, 4% 92%, 0 18%)'},
    },
    {
      id: 4,
      tag: "PROJECT",
      title: "DoKuće.rs",
      role: "Full Stack Developer",
      description: "Developed external dashboard, custom theme additions and functionalities, and overall general setup for the Shopify platform. The external dashboard used for creating, storing and managing products, categories and tags and overall general inventory/product management.",
      technologies: "Shopify / Liquid / PHP / Laravel / HTML / CSS / Bootstrap / JavaScript / jQuery / SQL",
      customStyle:{clipPath: 'polygon(100% 0, 100% 100%, 82% 100%, 4% 92%, 0 18%)'},
    },
    {
      id: 5,
      tag: "COMPANY / PROJECT",
      title: "winsedswiss: Dashboard & Job Manager",
      role: "Full Stack Developer",
      description: "Developed Company Dashboard Application for central access to all applications, communication, task assignment & overview, file storage etc.",
      technologies: "PHP / Laravel / HTML / CSS / Bootstrap / JavaScript / jQuery / SQL",
      link: "https://dashboard.grulovic.rs/",
      note: "Email: stefan.grulovic@insed.ch. \nPassword: 123123123",
      customStyle:{clipPath: 'polygon(100% 15%, 100% 87%, 64% 97%, 4% 92%, 0 0)'},
    },
    {
      id: 6,
      tag: "BACHELOR THESIS",
      title: "HERMES",
      role: "Full Stack Developer",
      description: "My thesis project involved developing a social-commerce web application which intends to be convenient, scalable, and secure providing businesses and consumers with a platform through which they could communicate and trade.",
      technologies: "PHP / Laravel / HTML / CSS / Bootstrap / JavaScript / jQuery / SQL",
      link: "https://hermes.grulovic.rs/",
      note: "Email: business@email.com. \nPassword: 123",
      customStyle:{clipPath: 'polygon(100% 0, 100% 100%, 82% 100%, 4% 92%, 0 18%)'},
    }
  ];

  return (<div style={{background: '#0e1111', minHeight:'100vh'}}>
        <div className={'container'}>
          <div className={'ColumnWrapper'}>
            <div className={'column'}>
              <div style={{
                padding: '20px',
              }}>
                <h1 style={{
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}>
                  <img src={'/images/signature.gif'} alt={'Signature'} width={200} height={100} style={{
                    width: '300px',
                    height: '100px',
                    objectFit: 'contain',
                  }}/>
                  <div>Stefan<span className="blink_me">_</span></div>
                </h1>

                <div style={{
                  width:'100%',
                  maxWidth: '700px',
                  marginBottom: '20px',
                  paddingLeft: '10px',
                }}>
                  <h2 style={{color: 'white', fontSize: '20px'}}>Full-Stack Developer</h2>
                  <p style={{color: 'white'}}> Backend (PHP/Laravel) • Frontend(next.js/React) • Mobile(React
                    Native)</p>

                  <p className="text-white">
                    <br/>Computer science engineer with experience in software and web
                    development. I have an eye for design and a strong determination to
                    always learn more, making for a dynamic duo that produces
                    intriguing work.
                  </p>

                  <div style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: "20px",
                  }}>
                    <a
                        href="https://drive.google.com/file/d/14vrIqutRtDCZv8kirYO2k-Kx9cc3k2NE/view?usp=sharing"
                        className="button cv_button"
                        target="_blank"
                        rel="noopener"
                    >GET CV / RESUME</a>

                    <p className="nav-links link-decoration-none">
                      <a aria-label="Facebook" href="https://www.facebook.com/Stefan.Grulovic" target="_blank" rel="noopener noreferrer">
                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg>
                      </a>

                      <a aria-label="Instagram" href="https://www.instagram.com/sgrulovic/" target="_blank" rel="noopener noreferrer">
                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"></path></svg>
                      </a>
                      <a aria-label="LinkedIn" href="https://www.linkedin.com/in/grulovic/" target="_blank" rel="noopener noreferrer">
                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>
                      </a>

                      <a aria-label="Phone" href="tel: +381 62 173 7256">
                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM94 416c-7.033 0-13.057-4.873-14.616-11.627l-14.998-65a15 15 0 0 1 8.707-17.16l69.998-29.999a15 15 0 0 1 17.518 4.289l30.997 37.885c48.944-22.963 88.297-62.858 110.781-110.78l-37.886-30.997a15.001 15.001 0 0 1-4.289-17.518l30-69.998a15 15 0 0 1 17.16-8.707l65 14.998A14.997 14.997 0 0 1 384 126c0 160.292-129.945 290-290 290z"></path></svg>
                      </a>

                      <a aria-label="Email" href="mailto:stefan.grulovic@gmail.com">
                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z"></path></svg>
                      </a>
                    </p>
                  </div>
                </div>

                <h2 id="projects" className="text-left text-white" style={{paddingLeft: '10px'}}>
                  Companies / Projects ({currentProject + 1}<span className="blink_me">_</span>/{projects?.length})
                </h2>

                <section className="text-center" style={{
                  display: 'flex',
                  alignItems: 'center',

                }}>
                  <button
                      disabled={currentProject < 1}
                      className={'button_white'}
                      onClick={() => setCurrentProject(currentProject - 1)}
                  >{'<'}
                  </button>

                  <ProjectCard project={projects[currentProject]}/>

                  <button
                      disabled={currentProject + 1 === projects?.length}
                      className={'button_white'}
                      style={{
                        height: 'fit-content',
                        marginLeft:'10px',
                      }}
                      onClick={() => setCurrentProject(currentProject + 1)}
                  >
                    {'>'}
                  </button>
                </section>
              </div>
            </div>
            <div className={'column hero'} style={{
              height:'100%',
              position:'relative',
            }}>
              <img
                  className={'bobblehead glava'}
                  src={images[hoveredIndex] ?? images[4]}
                  alt={'Face'}
              />
              <img
                  className={'telo'}
                  src={'/images/telo_tiny_tiny.png'}
              />
            </div>
          </div>
        </div>


      </div>
  );
}

export default App;


